import { EventBus } from '@/plugins/eventbus.js';
import orgManagerService from "@/services/orgManagerService";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

class OrgUtil {

    constructor(context, org) {
      this.context = context;
      this.service = orgManagerService;
      this.tenant = tenant;  
      this.org = org;
    }

    isDevelopment() {
      return process.env.NODE_ENV == 'development';
    }

    isFreePlan() {
      if (!this.org) return false;
        return !this.org.license || this.org.license === 'FREE';
    }

    isBasicPlan() {
      if (!this.org) return false;
        return this.org.license === 'FREE' || this.org.license === 'BASIC';
    }
    hasDeeplinking() {
      if (!this.org) return false;
      return tenant.id == 'cofi';
    }

    isProPlan() {
      if (!this.org) return false;

      return tenant.id !== 'cofi' || this.org.license === 'PRO';
    }

    async refresh() {
      if (!this.org) return false;

      const id = this.org && this.org.id;
      var response = await orgManagerService.get(id);
      this.org = response.data;

      await EventBus.$emit('orgmanager-refresh', this);
      return true;
    }

    async requestSave() {
      EventBus.$emit('orgmanager-request-save');
    }
    async save() {
      if (!this.org) return false;

      try {
        var response = await orgManagerService.put(this.org);
        console.log('save response', response);
        this.context.$helpers.toastResponse(this.context, {status: 'OK'}, 'Organization saved successfully.');

        this.org = response.data;

        await EventBus.$emit('orgmanager-refresh', this);

        return true;
      }
      catch (ex) {
        console.log('Error in saving', ex);
        if (ex.response) {
          this.error = ex.response.data.msg;
        }
        // get a fresh copy to prevent unsaved data from showing
        this.refresh();
      }
      return false;
    }
  }

export default OrgUtil;